import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import {
  withFirestore,
} from "react-redux-firebase";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

function RemoveFriendModal(props: {
  firestore: any;
  onHide: any;
  user: any;
  me: any;
}) {
  const [loading, setLoading] = useState(false);

  const removeFriend = async () => {
    setLoading(true);

    let myId = props.me.id;
    const friendRefConfig = {
      collection: "users",
      doc: props.user.id,
    };
    const meRefConfig = {
      collection: "users",
      doc: myId,
    };

    await props.firestore.update(friendRefConfig, {
      friends: props.user.friends.filter((item: string) => item !== myId),
    });

    await props.firestore.update(meRefConfig, {
      friends: props.me.friends.filter((item: string) => item !== props.user.id),
    });

    setLoading(false);
    toast.dark(`${props.user.firstName} ${props.user.lastName} removed.`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
    props.onHide();
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Remove friend?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: colors.white, backgroundColor: colors.accent }}
      >
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <p>
            {props.user ? `${props.user.firstName} ${props.user.lastName}` : ""}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => props.onHide()}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            removeFriend();
          }}
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(RemoveFriendModal);
