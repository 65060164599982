import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouterHistory,
} from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../setup/App.scss";

import Sidebar from "react-sidebar";
import Calendar from "../pages/calendar-page/calendar";
import Home from "../pages/home-page/home";
//@ts-ignore
import Header from "../header/navbar";
import BigBoard from "pages/bigboard-page/bigboard";
import PDFBigBoard from "pages/bigboard-page/pdf-bigboard";
import Reports from "pages/reports-list-page/reports";
import Settings from "pages/settings-page/settings";
import SearchResults from "pages/search-page/search-results";
import LogIn from "pages/login/login";
import SignUp from "pages/login/signup";
import { useSelector } from "react-redux";
import NotLoggedInHome from "pages/home-page/logged-out-home";
import NotVerifiedHome from "pages/home-page/not-verified-home";
import Tos from "pages/login/tos";
import PrivacyPolicy from "pages/login/privacy-policy";
import ResetPass from "pages/login/reset-pass";
import PlayerPage from "pages/player-page/player-page";
import CollegePlayerPage from "pages/player-page/college-player-page";
import ScoutSidebar from "shared/sidebar";
import CreateReport from "pages/reports-create-page/create-report";
import DisplayReport from "pages/reports-display-page/display-report";
import EditReport from "pages/reports-create-page/edit-report";
import DisplayPDF from "pages/reports-display-page/display-pdf";
import DisplayReportNotification from "pages/reports-display-page/display-report-notification";
import ProspectsPage from "pages/prospects-page/prospects-page";
import NotificationsPage from "pages/notifications-page/notifications-page";
import ScholarshipsPage from "pages/scholarships-page/scholarships-page";
import PDFPage from "pages/pdf-page/pdf-page";
import { useFirestoreConnect } from "react-redux-firebase";
import colors from "constants/colors";
import ScholarshipsPDFPage from "pages/scholarships-page/scholarships-pdf";
import MonitorPage from "pages/monitor-page/monitor-page";
import MonitorDetailPage from "pages/monitor-page/monitor-detail-page";
import BugReportPage from "pages/bug-report/bug-report";
import TournamentDataPage from "pages/tournament-data/tournament-data";
import { useMe } from "hooks/users";
import { useFlags } from "hooks/flags";
import NCAAPlayerPage from "pages/player-page/player-ncaa";

const mql = window.matchMedia(`(min-width: 1400px)`);

export default function ScoutRouter() {
  const me = useMe();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarDocked, setSidebarDocked] = useState(true);
  const mode = useSelector((state: any) => state.firestore.data.mode);
  const flags = useFlags();

  useFirestoreConnect([{ collection: "mode", doc: "maintenance" }]);

  useEffect(() => {
    mql.addListener(mediaQueryChanged);
  }, []);

  const mediaQueryChanged = () => {
    setSidebarOpen(false);
    setSidebarDocked(mql.matches);
  };

  if (mode && mode.maintenance && mode.maintenance.active) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: colors.accent,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
        >
          <h3 style={{ fontWeight: 800, color: "#fff" }}>Scout</h3>
          <h3 style={{ fontWeight: 300, color: "#fff" }}>SENSE</h3>
        </div>
        <p style={{ color: "#fff" }}>{mode.maintenance.message}</p>
      </div>
    );
  }

  return (
    <Router>
      <div style={{ padding: 0, margin: 0 }}>
        {me.isLoaded && flags.isLoaded ? (
          me.isEmpty || !me || (flags.verified_users_only && !me.verified) ? (
            <Switch>
              <Route path="/login">
                <LogIn />
              </Route>
              <Route path="/signup">
                <SignUp />
              </Route>

              <Route path="/terms-of-service">
                <Tos />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/reset-password">
                <ResetPass />
              </Route>
              <Route path="/just-pdf/:userId/:reportId">
                <PDFPage />
              </Route>
              <Route path="/report">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                      hidePlayerSearch
                    />
                  </nav>
                  <div style={{ marginTop: "6rem" }}>
                    <BugReportPage />
                  </div>
                </div>
              </Route>

              {!me.isEmpty && !!me && (
                <Route path="/settings">
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <Settings />
                    </div>
                  </div>
                </Route>
              )}

              <Route path="/">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  {me?.isEmpty || !me ? (
                    <NotLoggedInHome />
                  ) : (
                    <NotVerifiedHome />
                  )}
                </div>
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/login">
                <LogIn />
              </Route>
              <Route path="/signup">
                <SignUp />
              </Route>

              <Route path="/report">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                      hidePlayerSearch
                    />
                  </nav>
                  <div style={{ marginTop: "6rem" }}>
                    <BugReportPage />
                  </div>
                </div>
              </Route>

              <Route path="/prospects">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <ProspectsPage />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/pdf-scholarships/:year">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "5rem" }}>
                    <ScholarshipsPDFPage />
                  </div>
                </div>
              </Route>
              <Route path="/scholarships">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "5rem" }}>
                    <ScholarshipsPage />
                  </div>
                </div>
              </Route>
              <Route path="/calendar">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <Calendar />
                    </div>
                  </div>
                </Sidebar>
              </Route>
              <Route path="/notifications">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <NotificationsPage />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/pdf-bigboard/:boardId/:boardOwnerId/:boardYear">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <PDFBigBoard />
                  </div>
                </div>
              </Route>

              <Route path="/bigboard/:boardId/:boardOwnerId/:boardYear">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <BigBoard />
                  </div>
                </div>
              </Route>
              <Route path="/player/:id">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <PlayerPage />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/ncaa_player/:id">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <NCAAPlayerPage />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/collegeplayer/:id">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <CollegePlayerPage />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/create-report/:playerId">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <CreateReport />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/edit-report/:reportId">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <EditReport />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/just-pdf/:userId/:reportId">
                <PDFPage />
              </Route>

              <Route path="/display-report/:reportId">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <DisplayReport />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/display-pdf/:reportId">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <DisplayPDF />
                  </div>
                </div>
              </Route>

              <Route path="/shared-report/:notificationId">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <DisplayReportNotification />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/reports">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <Reports />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/post-detail/:monitorId">
                <Sidebar
                  sidebar={
                    <ScoutSidebar
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  }
                  open={sidebarOpen}
                  onSetOpen={setSidebarOpen}
                  docked={sidebarDocked}
                  shadow={false}
                  pullRight={true}
                >
                  <div>
                    <nav>
                      <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        setSidebarDocked={setSidebarDocked}
                      />
                    </nav>
                    <div style={{ marginTop: "9.5rem" }}>
                      <MonitorDetailPage />
                    </div>
                  </div>
                </Sidebar>
              </Route>

              <Route path="/monitor">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "4rem" }}>
                    <MonitorPage />
                  </div>
                </div>
              </Route>

              <Route path="/search-results">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <SearchResults />
                  </div>
                </div>
              </Route>
              <Route path="/settings">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <Settings />
                  </div>
                </div>
              </Route>
              <Route path="/terms-of-service">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <Tos />
                  </div>
                </div>
              </Route>
              <Route path="/privacy-policy">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <PrivacyPolicy />
                  </div>
                </div>
              </Route>
              <Route path="/reset-password">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <ResetPass />
                  </div>
                </div>
              </Route>
              <Route path="/demo/tournaments">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={false}
                      setSidebarOpen={() => {}}
                      setSidebarDocked={() => {}}
                      hidePlayerSearch
                    />
                  </nav>
                  <div style={{ marginTop: "5rem" }}>
                    <TournamentDataPage />
                  </div>
                </div>
              </Route>
              <Route path="/">
                <div>
                  <nav>
                    <Header
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      setSidebarDocked={setSidebarDocked}
                    />
                  </nav>
                  <div style={{ marginTop: "9.5rem" }}>
                    <Home />
                  </div>
                </div>
              </Route>
            </Switch>
          )
        ) : (
          <div />
        )}
      </div>
    </Router>
  );
}
