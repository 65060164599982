import React, { ReactNode, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import PortalResult from "./PortalResult";
import { Switch as MUISwitch } from "@material-ui/core";
import ConferenceDropwdown from "dropdowns/conference-dropdown";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import InstitutionDropdown from "dropdowns/Institution-dropdown";
import EditSavedSearchModal from "modals/edit-saved-search-modal";
import { Button } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import colors from "constants/colors";
import { auth } from "store/store";
import { useSelector } from "react-redux";
import moment from "moment";
import UnderlineButton from "shared/underline-button";

/*****************************************************************************
 * Types
 *****************************************************************************/

type TransferStatus = (typeof TRANSFER_STATUS)[number];
type Division = (typeof DIVISIONS)[number];
type FilterId = keyof InitialFilters;

export type InitialFilters = {
  institution: string;
  graduate: boolean;
  mtr: boolean;
  transferStatus: TransferStatus[];
  conference: string;
  year: number;
  division: Division[];
};

/*****************************************************************************
 * Constants
 *****************************************************************************/

const TRANSFER_STATUS = ["INC", "WTH", "MAT"];
const DIVISIONS = [1, 2, 3];

const initialFilters: InitialFilters = {
  institution: "",
  graduate: false,
  mtr: false,
  transferStatus: [],
  conference: "",
  year: new Date().getFullYear(),
  division: [],
};

/*****************************************************************************
 * AdvancedSearchPortal
 *****************************************************************************/

export default function AdvancedSearchPortal({
  setShowAdvancedOptions,
  setShowFilterIcon,
  showAll,
  handleShowAll,
  isNCAA,
  showAdvancedOptions,
}: {
  setShowAdvancedOptions: (value: boolean) => void;
  setShowFilterIcon: (value: boolean) => void;
  showAll: boolean;
  handleShowAll: () => void;
  isNCAA: boolean;
  showAdvancedOptions: boolean;
}) {
  const [filters, updateFilters] = useImmer(initialFilters);
  const [selectedTabCount, setSelectedTabCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedTabItems, setSelectedTabItems] = useState<
    Record<FilterId, number>
  >({
    institution: 0,
    graduate: 0,
    mtr: 0,
    transferStatus: 0,
    conference: 0,
    year: 0,
    division: 0,
  });
  const firestore = useFirestore();
  const [institutions, setInstitutions] = useState<any[]>([]);
  const [editSavedSearch, setEditSavedSearch] = React.useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState<Set<FilterId>>(new Set());
  const mySearches = useSelector(
    (state: any) => state.firestore.data.mySearches
  );

  const getInstitutions = async () => {
    try {
      const institutionsSnapshot = await firestore
        .collection("institutions")
        .get();
      const institutions = institutionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return institutions;
    } catch (error) {
      console.error("Error fetching institutions: ", error);
      return [];
    }
  };

  useEffect(() => {
    getInstitutions().then((institutions) => {
      setInstitutions(institutions);
    });
  }, []);

  function resetAllFilters() {
    setFiltersApplied(false);
    updateFilters(() => initialFilters);
    setSelectedTabCount(0);
    setSelectedTabs(new Set());
  }

  const handleApplyFilters = async () => {
    try {
      setShowFilterIcon(true);
      setShowAdvancedOptions(false);
      setFiltersApplied(true);
      handleShowAll();
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  useEffect(() => {
    setFiltersApplied(false);
  }, []);

  const handleTabSelection = (tabId: FilterId, wasSelected: boolean) => {
    setSelectedTabItems((prevSelectedItems) => {
      const updatedSelectedItems = { ...prevSelectedItems };
      updatedSelectedItems[tabId] = wasSelected
        ? Math.max(updatedSelectedItems[tabId] - 1, 0)
        : updatedSelectedItems[tabId] + 1;

      setSelectedTabs((prevSelectedTabs) => {
        const newSelectedTabs = new Set(prevSelectedTabs);
        if (updatedSelectedItems[tabId] > 0) {
          newSelectedTabs.add(tabId);
        } else {
          newSelectedTabs.delete(tabId);
        }
        setSelectedTabCount(newSelectedTabs.size);
        return newSelectedTabs;
      });

      return updatedSelectedItems;
    });
  };

  const userId = auth.currentUser?.uid;

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "searches" }],
      storeAs: `mySearches`,
    },
  ]);

  const filteredSavedSearches = () => {
    let saved = Object.keys(mySearches || {})
      .map((key) => (mySearches ? { ...mySearches[key], id: key } : null))
      .filter((item) => item !== null);
    if (isNCAA) {
      saved = saved.filter((item) => item.ncaaPortal);
    }

    return saved;
  };

  const tabs = [
    {
      id: "institution",
      title: "Institution",
      body: (
        <InstitutionDropdown
          value={filters.institution}
          onSelect={(option: { id: string; institution: string } | null) => {
            updateFilters((draft) => {
              draft.institution = option?.institution || "";
            });
            handleTabSelection(
              "institution",
              option?.institution === initialFilters.institution
            );
          }}
          options={institutions || []}
          placeHolder="Institutions"
        />
      ),
    },
    {
      id: "graduate",
      title: "Graduate",
      body: (
        <MUISwitch
          checked={filters.graduate}
          onChange={() => {
            updateFilters((draft) => {
              draft.graduate = !filters.graduate;
            });
            handleTabSelection(
              "graduate",
              filters.graduate !== initialFilters.graduate
            );
          }}
        />
      ),
    },
    {
      id: "mtr",
      title: "MTR",
      body: (
        <MUISwitch
          checked={filters.mtr}
          onChange={() => {
            updateFilters((draft) => {
              draft.mtr = !filters.mtr;
            });
            handleTabSelection("mtr", filters.mtr !== initialFilters.mtr);
          }}
        />
      ),
    },
    {
      id: "transferStatus",
      title: "Transfer Status",
      body: (
        <ListSelector
          id="transferStatus"
          list={TRANSFER_STATUS}
          filters={filters}
          updateFilters={updateFilters}
          onTabSelect={(wasSelected) =>
            handleTabSelection("transferStatus", wasSelected)
          }
        />
      ),
    },
    {
      id: "conference",
      title: "Conference",
      body: (
        <ConferenceDropwdown
          selectItem={(item) => {
            const selectedConference = item[0] || "";
            updateFilters((draft) => {
              draft.conference = selectedConference;
            });
            handleTabSelection(
              "conference",
              selectedConference === initialFilters.conference
            );
          }}
          allowAll={true}
        />
      ),
    },
    {
      id: "year",
      title: "Year",
      body: (
        <input
          type="number"
          value={filters.year}
          onChange={(e) => {
            const newYear = Number(e.target.value);
            updateFilters((draft) => {
              draft.year = newYear;
            });
            handleTabSelection("year", newYear === initialFilters.year);
          }}
        />
      ),
    },
    {
      id: "division",
      title: "Division",
      body: (
        <ListSelector
          id="division"
          list={DIVISIONS}
          filters={filters}
          updateFilters={updateFilters}
          onTabSelect={(wasSelected) =>
            handleTabSelection("division", wasSelected)
          }
        />
      ),
    },
  ] as { id: FilterId; title: string; body: ReactNode }[];

  return (
    <>
      {!showAll ? (
        <div>
          <div className="tabContainer">
            {tabs.map(({ id, title, body }) => (
              <div key={id} className="tabContent">
                <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>
                  {title}
                </h4>
                {body}
              </div>
            ))}
          </div>
          <div style={{ marginTop: "20px" }}>
            <button
              style={{
                backgroundColor: "white",
                color: "#003366",
                padding: 4,
                border: "2px solid white",
                width: "20%",
                borderRadius: 8,
                cursor: "pointer",
              }}
              onClick={resetAllFilters}
            >
              Reset Filters
            </button>
            {selectedTabCount > 0 && (
              <button
                style={{
                  backgroundColor: "white",
                  color: "#003366",
                  padding: 4,
                  border: "2px solid white",
                  width: "20%",
                  borderRadius: 8,
                  cursor: "pointer",
                  marginLeft: 20,
                }}
                onClick={handleApplyFilters}
              >
                Apply Filters ({selectedTabCount})
              </button>
            )}
          </div>
        </div>
      ) : (
        <PortalResult
          showWithoutFilter={true}
          limit={true}
          filters={filters}
          setShowFilterIcon={setShowFilterIcon}
        />
      )}
      {isNCAA && showAdvancedOptions && (
        <div
          style={{
            width: "100%",
            marginLeft: "5%",
            height: 1,
            backgroundColor: "#fff",
            marginTop: 40,
            marginBottom: 25,
          }}
        />
      )}
      {isNCAA && showAdvancedOptions && (
        <div>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyItems: "center",
              paddingLeft: 20,
              paddingRight: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h4
                style={{ fontWeight: 300, fontSize: 20, color: colors.white }}
              >
                MY SAVED SEARCHES
              </h4>
              <Button
                variant="secondary"
                style={{
                  marginTop: -12,
                  color: "white",
                  fontSize: 30,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                }}
                onClick={() => setShowSaved(!showSaved)}
              >
                {showSaved ? <AiFillCaretDown /> : <AiFillCaretRight />}
              </Button>
            </div>
          </div>
          {showSaved && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {filteredSavedSearches().map((item, index) =>
                item && item.id && item.name ? (
                  <div
                    key={index}
                    style={{
                      padding: 8,
                      backgroundColor: colors.white,
                      color: colors.accent,
                      borderRadius: 5,
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      width: "40%",
                    }}
                    onClick={() => {
                      updateFilters(() => item.query);
                      const newSelectedTabItems: Record<FilterId, number> = {
                        year: 0,
                        conference: 0,
                        division: 0,
                        institution: 0,
                        graduate: 0,
                        mtr: 0,
                        transferStatus: 0,
                      };
                      let tabCount = 0;
                      Object.entries(initialFilters).forEach(
                        ([key, initialValue]) => {
                          const savedValue = item.query[key];
                          const isDifferent = Array.isArray(initialValue)
                            ? JSON.stringify(initialValue) !==
                              JSON.stringify(savedValue)
                            : initialValue !== savedValue;
                          if (isDifferent) {
                            newSelectedTabItems[key as FilterId] =
                              Array.isArray(savedValue)
                                ? savedValue.length
                                : savedValue
                                ? 1
                                : 0;
                            tabCount++;
                          }
                        }
                      );
                      setSelectedTabItems(newSelectedTabItems);
                      setSelectedTabCount(tabCount);
                      setFiltersApplied(tabCount > 0);
                    }}
                  >
                    {/* Display Item Name and Date */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Name of the item */}
                      <p style={{ fontWeight: "bold" }}>{item.name}</p>
                      {/* Creation Date */}
                      <p style={{ fontStyle: "italic", opacity: 0.7 }}>
                        {moment(
                          item.timeCreated
                            ? new Date(item.timeCreated.seconds * 1000)
                            : new Date()
                        ).format("M/D")}
                      </p>
                    </div>

                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <UnderlineButton
                        color={colors.accent}
                        text={"edit"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditSavedSearch(item);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div key={index} />
                )
              )}
            </div>
          )}
        </div>
      )}
      <EditSavedSearchModal
        show={editSavedSearch !== null}
        savedSearch={editSavedSearch}
        onHide={() => setEditSavedSearch(null)}
        title={"Rename NCAA Saved Search"}
        ncaaPortal={true}
      />
    </>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/
function ListSelector({ id, list, filters, updateFilters, onTabSelect }) {
  const handleOptionSelect = (item) => {
    updateFilters((draft) => {
      const filterList = draft[id];
      const newFilterList = filterList.includes(item)
        ? filterList.filter((i) => i !== item)
        : [...filterList, item];
      draft[id] = newFilterList;
    });
    onTabSelect(filters[id].includes(item));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {list.map((item, index) => (
        <button
          key={index}
          onClick={() => handleOptionSelect(item)}
          style={{
            backgroundColor: filters[id].includes(item)
              ? "white"
              : "transparent",
            color: filters[id].includes(item) ? "#003366" : "#fff",
            padding: 4,
            border: filters[id].includes(item)
              ? "2px solid transparent"
              : "2px solid white",
            width: "30%",
            borderRadius: 8,
            marginBottom: 8,
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
}
