import React from "react";
import { PlayerService } from "services/PlayerService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import colors from "constants/colors";
import { SAFE_toFixed } from "services/utils";

function PopOver(props: {
  player: any;
  skipPosition?: boolean;
  disabledMode?: "pbr_disabled" | "pg_disabled" | "both" | "none";
}) {
  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val) => {
    let stringVal = val;
    if (val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const isPitcher = () => {
    const pitcher = props.player.playerDetails?.sr_position
      .toLowerCase()
      .includes("pitcher");
    if (pitcher) {
      return true;
    } else {
      return false;
    }
  };

  const renderPitchingStats = (stats: any) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <thead>
          <tr>
            {th("Year")}
            {th("TM")}
            {th("LG")}
            {th("W")}
            {th("L")}
            {th("W-L%")}
            {th("ERA")}
            {th("RA")}
            {th("GS")}
            {th("GF")}
            {th("CG")}
            {th("SHO")}
            {th("SV")}
            {th("IP")}
            {th("H")}
            {th("R")}
            {th("ER")}
            {th("HR")}
            {th("G")}
            {th("BB")}
            {th("IBB")}
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats).map(([year, dataObjects]: [string, any]) =>
            Object.entries(dataObjects).map(([team, data]: [string, any]) => (
              <tr key={year} style={{ backgroundColor: colors.lightGray }}>
                {td(year)}
                {td(props.player?.institution)}
                {td(data.league)}
                {td(data.wins)}
                {td(data.losses)}
                {td(data["win-loss-percentage"])}
                {td(data["earned-run-average"])}
                {td(data["run-average"])}
                {td(data["games-started"])}
                {td(data["games-finished"])}
                {td(data["complete-game"])}
                {td(data.shutouts)}
                {td(data.saves)}
                {td(data["innings-pitched"])}
                {td(data["hits-allowed"])}
                {td(data["runs-allowed"])}
                {td(data["earned-runs-allowed"])}
                {td(data["home-runs-per-nine"])}
                {td(data["games-played"])}
                {td(data["bases-on-balls"])}
                {td(data["intentional-bases-on-balls"])}
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };

  const renderBattingStats = (stats: any) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <thead>
          <tr>
            {th("Year")}
            {th("TM")}
            {th("LG")}
            {th("G")}
            {th("PA")}
            {th("AB")}
            {th("R")}
            {th("H")}
            {th("2B")}
            {th("3B")}
            {th("HR")}
            {th("RBI")}
            {th("SB")}
            {th("CS")}
            {th("BB")}
            {th("SO")}
            {th("BA")}
            {th("OBP")}
            {th("SLG")}
            {th("OPS")}
            {th("TB")}
            {th("GDP")}
            {th("HBP")}
            {th("SH")}
            {th("SF")}
            {th("IBB")}
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats).map(([year, dataObjects]: [string, any]) =>
            Object.entries(dataObjects).map(([team, data]: [string, any]) => (
              <tr key={year} style={{ backgroundColor: colors.lightGray }}>
                {td(year)}
                {td(props.player?.institution)}
                {td(data.league)}
                {td(data.games)}
                {td(data["plate-appearances"])}
                {td(data["at-bats"])}
                {td(data.runs)}
                {td(data.hits)}
                {td(data.doubles)}
                {td(data.triples)}
                {td(data["home-runs"])}
                {td(data.rbis)}
                {td(data["stolen-bases"])}
                {td(data["caught-stealing"])}
                {td(data["bases-on-balls"])}
                {td(data.strikeouts)}
                {td(data["batting-avg"])}
                {td(data["on-base-percentage"])}
                {td(data["slugging-percentage"])}
                {td(data["on-base-plus-slugging"])}
                {td(data["total_bases"])}
                {td(data["double-plays-grounded-into"])}
                {td(data["hit-by-pitch"])}
                {td(data["sacrifice-hits"])}
                {td(data["sacrifice-flys"])}
                {td(data["IBB"])}
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };

  const renderNcaaDetails = () => {
    const player = props.player.playerDetails;
    const isPlayerPitcher = isPitcher();
    const stats = isPlayerPitcher
      ? player.sr_pitching_stats
      : player.sr_batting_stats;
    return isPlayerPitcher
      ? renderPitchingStats(stats)
      : renderBattingStats(stats);
  };

  return (
    <>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement={"right"}
        overlay={
          <Popover id={`popover-positioned-right`}>
            {props.player && props.player.playerDetails ? (
              <>
                <Popover.Content style={{ margin: 0, padding: 0 }}>
                  {renderNcaaDetails()}
                </Popover.Content>
              </>
            ) : (
              <>
                <Popover.Title
                  as="h3"
                  style={{
                    backgroundColor: "#467DB3",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Player Details Not Found
                </Popover.Title>
                <Popover.Content
                  style={{ margin: "10px", textAlign: "center" }}
                >
                  No additional information available for this player.
                </Popover.Content>
              </>
            )}
          </Popover>
        }
      >
        <Link
          to={`/ncaa_player/${props.player?.external_id}`}
          className="row-p"
          style={{
            color: colors.accent,
            textDecoration: "underline",
            textDecorationColor: colors.accent,
            textDecorationStyle: "solid",
            whiteSpace: "nowrap",
          }}
        >
          {PlayerService.getName(props.player)}
          <span style={{ fontWeight: 700 }}>
            {props.skipPosition || false
              ? ""
              : ` (${props.player?.primary_position}${
                  props.player?.alt_position
                    ? `, ${props.player?.alt_position}`
                    : ""
                })`}
          </span>
        </Link>
      </OverlayTrigger>
    </>
  );
}

export default PopOver;
