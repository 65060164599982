import firebase from "firebase/app";
import "firebase/firestore";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getAhowAllQuery = async (pageSize: number) => {
  try {
    // Fetch NCAA Portals data
    const ncaaPortalsSnapshot = await firebase
      .firestore()
      .collection("ncaa_portals")
      .limit(pageSize)
      .get();

    const ncaaPortals: any[] = ncaaPortalsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch matching Player Details for each NCAA Portal
    const playersWithDetails = await Promise.all(
      ncaaPortals.map(async (portal) => {
        try {
          const { first_name, last_name } = portal;
          const formattedFirstName = capitalizeFirstLetter(first_name || "");
          const formattedLastName = capitalizeFirstLetter(last_name || "");
          const sr_name = `${formattedFirstName} ${formattedLastName}`;

          if (!sr_name) {
            return { ...portal, playerDetails: null };
          }

          // Query the `sr_player_detail` collection for matching sr_name
          const playerDetailsSnapshot = await firebase
            .firestore()
            .collection("sr_player_detail")
            .where("sr_name", "==", sr_name)
            .limit(1)
            .get();

          const playerDetails = playerDetailsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          return { ...portal, playerDetails: playerDetails[0] || null };
        } catch {
          return { ...portal, playerDetails: null };
        }
      })
    );
    return playersWithDetails;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

export const fetchFilterData = async (pageSize = 20, filters) => {
  try {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
      firebase.firestore().collection("ncaa_portals");
    if (filters.conference) {
      query = query.where("conference", "==", filters.conference);
    }
    if (filters.division && filters.division.length > 0) {
      query = query.where("division", "in", filters.division);
    }
    if (typeof filters.graduate === "boolean") {
      query = query.where("is_graduate", "==", filters.graduate ? "Y" : "N");
    }
    if (filters.institution) {
      query = query.where("institution", "==", filters.institution);
    }
    if (typeof filters.mtr === "boolean") {
      query = query.where(
        "multiple_transfer_portal_records",
        "==",
        filters.mtr ? "Y" : "N"
      );
    }
    if (filters.transferStatus && filters.transferStatus.length > 0) {
      query = query.where("transfer_status", "in", filters.transferStatus);
    }
    if (filters.year) {
      query = query.where("year", "==", filters.year);
    }

    const snapshot = await query.limit(pageSize).get();
    const ncaa_portals = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Fetch matching Player Details for each NCAA Portal
    const playersWithDetails = await Promise.all(
      ncaa_portals.map(async (portal: any) => {
        try {
          const { first_name, last_name } = portal;
          const formattedFirstName = capitalizeFirstLetter(first_name || "");
          const formattedLastName = capitalizeFirstLetter(last_name || "");
          const sr_name = `${formattedFirstName} ${formattedLastName}`;

          if (!sr_name) {
            return { ...portal, playerDetails: null };
          }

          // Query the `sr_player_detail` collection for matching sr_name
          const playerDetailsSnapshot = await firebase
            .firestore()
            .collection("sr_player_detail")
            .where("sr_name", "==", sr_name)
            .limit(1)
            .get();

          const playerDetails = playerDetailsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          return { ...portal, playerDetails: playerDetails[0] || null };
        } catch {
          return { ...portal, playerDetails: null };
        }
      })
    );
    return playersWithDetails;
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return { data: [] };
  }
};

export const singlePlayerDetails = async (
  playerId: string
) => {
  try {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
      firebase.firestore().collection("ncaa_portals");
    query = query.where("external_id", "==", playerId);

    const snapshot = await query.limit(1).get();
    const ncaa_portals = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Fetch matching Player Details for each NCAA Portal
    const playersWithDetails = await Promise.all(
      ncaa_portals.map(async (portal: any) => {
        try {
          const { first_name, last_name } = portal;
          const formattedFirstName = capitalizeFirstLetter(first_name || "");
          const formattedLastName = capitalizeFirstLetter(last_name || "");
          const sr_name = `${formattedFirstName} ${formattedLastName}`;

          if (!sr_name) {
            return { ...portal, playerDetails: null };
          }

          // Query the `sr_player_detail` collection for matching sr_name
          const playerDetailsSnapshot = await firebase
            .firestore()
            .collection("sr_player_detail")
            .where("sr_name", "==", sr_name)
            .limit(1)
            .get();

          const playerDetails = playerDetailsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          return { ...portal, playerDetails: playerDetails[0] || null };
        } catch {
          return { ...portal, playerDetails: null };
        }
      })
    );
    return playersWithDetails;
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return { data: [] };
  }
};
