import React from "react";
import ReactDOM from "react-dom";
import "./setup/index.css";
import Favicon from "react-favicon";
import App from "./App";
import * as serviceWorker from "./setup/serviceWorker";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Intercom from 'react-intercom';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <Favicon url={require("./assets/ball-navy.png")} />
    </QueryClientProvider>
      <Intercom appID="tq4ks0p8" name="Scout1.0"/>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
